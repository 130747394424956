import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import WorkCard from "../components/WorkCard"
import Helmet from "react-helmet"
import resetTransitionHomePage from "../modules/resetTransitionOnHomePage"
import ogImage from "../../content/assets/velvetzoo-og-image.jpg"
import ogImageSq from "../../content/assets/velvetzoo-og-image-1200.jpg"

const WorksIndex = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const works = data.allMarkdownRemark.edges
  const mainTitle = "Selected Works"
  const image = ogImage
  const imageSq = ogImageSq
  let workCounter = 0

  if (typeof window !== "undefined") {
    setTimeout(resetTransitionHomePage, 1100)
  }

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Velvetzoo Selected Works"
        keywords={[`Graphic Design`, `UI`, `UX`, `Brand Design`, `development`]}
        image={image}
        imageSq={imageSq}
      />
      <Helmet>
        <body className="main works" id="body" />
      </Helmet>
      {/* <Bio /> */}
      {data.site.siteMetadata.description && (
        <header id="pageHead" className="page-head">
          <h1 id="pageTitle" className="page-head-title big-font">
            {mainTitle}
          </h1>
        </header>
      )}
      {/* <Works /> */}
      <section
        id="works"
        className="section-works"
        style={{ backgroundColor: "#000" }}
      >
        <div className="app-container">
          <div className="p-bottom-md">
            <h2 className="big-font">Portfolio</h2>

            <p>
              A selection of works that cover UX/UI design projects, Web apps
              Development, Graphic Design and Branding
            </p>
          </div>

          <div className="post-feed">
            {works.map(({ node }) => {
              workCounter++
              return (
                <WorkCard
                  key={node.fields.slug}
                  count={workCounter}
                  node={node}
                  postClass={`post`}
                />
              )
            })}
          </div>
        </div>
      </section>
    </Layout>
  )
}
const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___id], order: ASC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            longDesc
            clientDesc
            workDesc
            tags
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <WorksIndex location={props.location} props data={data} {...props} />
    )}
  />
)
